*{
    margin:0;
    padding:0;
    box-sizing: border-box;

}

body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: #f1f5f8;
    color: black;
}

.bookList{
    width:  90vw;
    max-width: 1170px;
    margin: 5rem auto;
    display: grid;
    gap: 2rem;
}

@media screen and (min-width:768px){
    .bookList{
        grid-template-columns: repeat(3, 1fr);
        align-items: start;
    }
}
.book{
    background-color: #fff;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    position: relative;
}
.book img{
    width: 100%;
    object-fit: cover;
}

.book h2{
   margin-top: 1rem;
   font-size: 1rem;
}
.book p{
    margin: 1.5rem 0 0 0.5rem;
}